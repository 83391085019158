/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/vue@2.5.16/dist/vue.min.js
 * - /npm/http-vue-loader@1.3.4/src/httpVueLoader.min.js
 * - /npm/vue-router@3.0.1/dist/vue-router.min.js
 * - /npm/vuex@3.0.1/dist/vuex.min.js
 * - /npm/vue-i18n@7.6.0/dist/vue-i18n.min.js
 * - /npm/babel-polyfill@6.26.0/dist/polyfill.min.js
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
